<template>
  <div>
    <!-- todo - https://stackoverflow.com/questions/50392918/vuetify-how-to-preselect-active-tab
     -->
    <v-tabs v-model="activetab" align-with-title v-if="!reportMode">
      <v-tab  v-for="tab of tabs"  :key="tab.id" @click="WarnUser(tab.to)"
      :id="`odTransSequenceTab${tab.id}`"
      :class="`od-trans-sequence-tab-${tab.id}`">
        <span>{{ tab.caption }}</span>
      </v-tab>
      <!--
      <v-tab
        :to="{ name: 'Official Depository - Deposits & Vouchers' }"
      >
      Deposits &amp; Vouchers
      </v-tab>
      <v-tab
        :to="{ name: 'Official Depository - Browse' }"
      >
      Browse
      </v-tab>
      <v-tab
        :to="{ name: 'Official Depository - Reports' }"
      >
      Reports
      </v-tab>
      -->
    </v-tabs>
    <div v-if="dialog">
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="headline blue--text text--darken-3">
              Warning !
            </v-card-title>
            <v-card-text>
             Navigating to a different page will cause the loss of selected records.
             Do you want to proceed ?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-3" text @click="cancel"> Cancel </v-btn>
              <v-btn color="blue darken-3" text @click="clearselection">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'OfficialDepository',
  components: {
  },
  data: () => ({
    dialog: false,
    path: '',
    activetab: 0,
    tabs: [
      { id: 1, to: { path: '/od/transactions' }, caption: 'Deposits & Vouchers' },
      { id: 2, to: { path: '/od/browse/departments' }, caption: 'Browse' },
      { id: 3, to: { path: '/reports' }, caption: 'Reports' },
      { id: 4, to: { path: '/od/utils/interestapportionment' }, caption: 'Utils' },
    ],
  }),
  methods: {
    WarnUser(path) {
      this.path = path;
      const currentpath = this.$router.currentRoute.path;
      if (this.selectedrecords > 0) {
        this.dialog = true;
      } else if (path.path !== currentpath) {
        this.$router.push(path);
      }
    },
    cancel() {
      this.dialog = false;
      this.activetab = 0;
    },
    clearselection() {
      this.dialog = false;
      this.$store.commit('OD/setselectedrecords', []);
      this.$router.push(this.path).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
    },
  },
  computed: {
    reportMode() {
      const { path } = this.$route;
      return path.indexOf('reports/') > -1;
    },
    selectedrecords() {
      return this.$store.state.OD.selectedrecords.length;
    },
  },
};
</script>
